import * as React from "react";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import AppBar from "../components/AppBar";
import Toolbar from "../components/Toolbar";
import logo from "../../static/images/webv2Logo.png";

const rightLink = {
  fontSize: 16,
  color: "primary",
  ml: 3,
};

function AppAppBar({ scrollHandler }) {
  return (
    <div>
      <AppBar
        position="fixed"
        color="primary"
        style={{
          //background: "transparent",
          //background: "rgba(0,0,0,0.6)",
          //background: "linear-gradient(180deg, black 0 50%, black 0% 0%)",
          // background: `linear-gradient(
          //   rgba(0, 0, 0, 0.0) 0%,
          //   rgba(0, 0, 0, 0.0) 70%,
          //   rgba(255, 0, 0, 1.0) 100%,
          // )`,
          background: `linear-gradient(180deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 100%)`,
        }}
      >
        <Toolbar sx={{ justifyContent: "center" }}>
          <Box
            sx={{
              //flex: 1,
              justifyContent: "center",
              alignItems: "center",
            }}
          />
          {/* <Link
            variant="h6"
            underline="none"
            color="inherit"
            href="/premium-themes/onepirate/"
            sx={{ fontSize: 24 }}
          >
            {"washoo"}
          </Link> */}
          <img
            style={{
              width: "5vh",
              //marginTop: "2vw",
              zIndex: 99,
              alignSelf: "center",
            }}
            src={logo}
            alt="increase priority"
          />
          <Box
            sx={{
              flex: 1,
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "flex-end",
              //alignSelf: "flex-end",
            }}
          >
            {/* <Link
              color="inherit"
              variant="h6"
              underline="none"
              href="/premium-themes/onepirate/sign-in/"
              sx={rightLink}
            >
              {'Sign In'}
            </Link> */}
            <Link
              variant="h6"
              underline="none"
              onClick={scrollHandler}
              // href="/src/sign-up/"
              color="rgba(31,157,216,1)"
              // sx={{ ...rightLink, color: "secondary.main" }}
              sx={{ ...rightLink, marginLeft: 20 }}
              xs={{ ...rightLink, marginLeft: 20 }}
              href={"https://onelink.to/7xccgj"}
            >
              {"Download"}
            </Link>
          </Box>
        </Toolbar>
      </AppBar>
      <Toolbar />
    </div>
  );
}

export default AppAppBar;
