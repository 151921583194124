import React from "react";
//import Home from "./Home";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Terms from "./Terms";
import Privacy from "./Privacy";
import "./App.css";
import "./index.css";
import Home from "./Home";
import AppFooter from "./modules/views/AppFooter";
import withRoot from "./modules/withRoot";
import HomeAr from "./HomeAr";

function App() {
  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/Ar" element={<HomeAr />} />
      </Routes>
    </Router>
  );
}

export default withRoot(App);
