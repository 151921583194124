import React from "react";
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
  MemoryRouter,
} from "react-router-dom";
// import { StaticRouter } from "react-router-dom/server";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import Container from "@mui/material/Container";
import Typography from "../components/Typography";
import TextField from "../components/TextField";
import Terms from "../../Terms";
import Privacy from "../../Privacy";

function Copyright() {
  return (
    <React.Fragment>
      <Link color={"rgba(150,150,150,1)"} href="">
        {"© "} washooo.app {new Date().getFullYear()}
      </Link>
    </React.Fragment>
  );
}
function LLC() {
  return (
    <React.Fragment>
      <Link color={"rgba(150,150,150,1)"} href="">
        {"© "} HMT for websites {new Date().getFullYear()}
      </Link>
    </React.Fragment>
  );
}

const iconStyle = {
  width: 48,
  height: 48,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: "primary.light",
  mr: 1,
  "&:hover": {
    bgcolor: "primary.dark",
  },

  borderRadius: 30,
};

const LANGUAGES = [
  {
    code: "en-US",
    name: "English",
  },
  {
    code: "fr-FR",
    name: "Français",
  },
];

// }
export default function AppFooter() {
  return (
    <Typography
      component="footer"
      sx={{ display: "flex", bgcolor: "rgba(30,30,30,1)" }}
    >
      <Container sx={{ my: 8, display: "flex" }}>
        <Grid container spacing={5}>
          <Grid item xs={6} sm={4} md={3}>
            <Grid
              container
              direction="column"
              justifyContent="flex-end"
              spacing={2}
              sx={{ height: 110 }}
            >
              {/* <Grid item sx={{ display: "flex" }}>
                <Box component="a" href="https://mui.com/" sx={iconStyle}>
                  <img
                    src="/static/themes/onepirate/appFooterFacebook.png"
                    alt="Facebook"
                  />
                </Box>
                <Box
                  component="a"
                  href="https://twitter.com/MUI_hq"
                  sx={iconStyle}
                >
                  <img
                    src="/static/themes/onepirate/appFooterTwitter.png"
                    alt="Twitter"
                  />
                </Box>
              </Grid> */}
              <Grid item>
                <Copyright />
                <Box>
                  <LLC />
                </Box>
              </Grid>
              {/* <Grid item>
                <Copyright />
                <Copyright />
              </Grid> */}
            </Grid>
          </Grid>
          <Grid item xs={6} sm={4} md={2}>
            <Typography
              variant="h6"
              marked="left"
              gutterBottom
              color="primary.light"
            >
              Legal
            </Typography>
            <Box component="ul" sx={{ m: 0, listStyle: "none", p: 0 }}>
              <Box component="li" sx={{ py: 0.5 }}>
                {/* <Link href="">Terms</Link> */}
                <React.Fragment>
                  <Link
                    color={"rgba(150,150,150,1)"}
                    href="/Terms"
                    to="/Terms"
                    //component={Terms}
                    //element={Terms}
                  >
                    Terms & conditions
                  </Link>{" "}
                  <Link
                    color={"rgba(150,150,150,1)"}
                    href="/Privacy"
                    to="/Privacy"
                  >
                    Privacy Policy
                  </Link>{" "}
                </React.Fragment>
              </Box>
              {/* <Box component="li" sx={{ py: 0.5 }}>
                <Link href="">Privacy</Link>
              </Box> */}
            </Box>
          </Grid>
          <Grid item xs={6} sm={4} md={4}>
            <Typography
              variant="h6"
              marked="left"
              gutterBottom
              color="primary.light"
            >
              Contact us
            </Typography>
            <Box component="ul" sx={{ m: 0, listStyle: "none", p: 0 }}>
              <Box component="li" sx={{ py: 0.5 }}>
                {/* <Link href="">Terms</Link> */}
                <React.Fragment>
                  <Box>
                    <Link color={"rgba(150,150,150,1)"} href="">
                      0100 112 6884
                    </Link>{" "}
                  </Box>
                  <Link color={"rgba(150,150,150,1)"} href="">
                    7 Wahran St., Tayran St., Nasr City Egypt
                  </Link>{" "}
                </React.Fragment>
              </Box>
              {/* <Box component="li" sx={{ py: 0.5 }}>
                <Link href="">Privacy</Link>
              </Box> */}
            </Box>
          </Grid>
          <Grid item xs={6} sm={4} md={2}>
            <Typography
              variant="h6"
              marked="left"
              gutterBottom
              color="primary.light"
            >
              Download
            </Typography>
            <Box component="ul" sx={{ m: 0, listStyle: "none", p: 0 }}>
              <Box component="li" sx={{ py: 0.5 }}>
                {/* <Link href="">Terms</Link> */}
                <React.Fragment>
                  <Box>
                    <Link
                      color={"rgba(150,150,150,1)"}
                      href="https://onelink.to/7xccgj"
                    >
                      IOS
                    </Link>{" "}
                  </Box>
                  <Link
                    color={"rgba(150,150,150,1)"}
                    href="https://onelink.to/7xccgj"
                  >
                    Android
                  </Link>{" "}
                </React.Fragment>
              </Box>
              {/* <Box component="li" sx={{ py: 0.5 }}>
                <Link href="">Privacy</Link>
              </Box> */}
            </Box>
          </Grid>
          <Grid item xs={6} sm={4} md={1}>
            <Box>
              <Link color={"rgba(350,350,350,1)"} href="/Ar" to="Ar">
                AR
              </Link>{" "}
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Typography>
  );
}
