import * as React from "react";
import { useRef } from "react";
import AppFooterAr from "./modules/views/AppFooterAr";
import ProductHeroAr from "./modules/views/ProductHeroAr";
import AppAppBar from "./modules/views/AppAppBar";
import withRoot from "./modules/withRoot";
import SignUp from "./SignUp";
import ProductHeroSecondAr from "./modules/views/ProductHeroSecondAr";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import ReviewCard from "./modules/components/ReviewCard";
import "./dot.css";
import Typography from "./modules/components/Typography";
import { Box } from "@mui/system";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
//import Home from "./Home";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Terms from "./Terms";
import Privacy from "./Privacy";
function HomeAr() {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 4,
    },
    desktop2: {
      breakpoint: { max: 3000, min: 1175 },
      items: 4,
    },
    desktop: {
      breakpoint: { max: 1175, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  const CustomDot = ({ onClick, ...rest }) => {
    const {
      onMove,
      index,
      active,
      carouselState: { currentSlide, deviceType },
    } = rest;
    const carouselItems = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15];

    return (
      <button
        className={active ? "active-Dot" : "inactive-Dot"}
        onClick={() => onClick()}
      ></button>
    );
  };
  const ref = useRef(null);
  const handleScroll = () => {
    ref.current?.scrollIntoView({ behavior: "smooth" });
  };
  return (
    <React.Fragment>
      <AppAppBar scrollHandler={handleScroll} />
      {/* <Floating /> */}
      <ProductHeroAr scrollHandler={handleScroll} />
      <ProductHeroSecondAr scrollHandler={handleScroll} />

      {/* <Box
        sx={{
          mt: { lg: "-80vw", xs: "-50vw", sm: "-80vw" },
        }}
        ref={ref}
      >
        <Typography
          color="secondary"
          align="center"
          variant="h2"
          sx={{
            mt: { sx: 10, sm: 10, xs: -10, lg: 20 },

            fontSize: { sx: 12, sm: 25, lg: 85, xs: 30 },
          }}
        >
          Do you own a carwash service ?
        </Typography>
        <Typography
          color="white"
          align="center"
          variant="h1"
          sx={{
            fontSize: { sx: 12, lg: 45, sm: 20, xs: 18 },
          }}
        >
          Join now and become a partner to unlock all the goodies
        </Typography>

        <SignUp />
      </Box> */}

      <AppFooterAr />

      {/* <Routes>
        <Route exact path="/" element={<Home />} />

        <Route path="terms" element={<Terms />} />

        <Route path="privacy" element={<Privacy />} />

        <Navigate to="/" element={<Home />} />
      </Routes> */}
    </React.Fragment>
  );
}

export default withRoot(HomeAr);
