import * as React from "react";
import Button from "../components/Button";
import Typography from "../components/Typography";
import ProductHeroLayout from "./ProductHeroLayout";
import backgroundImage from "../../static/images/webv2-02.png";
import backgroundImageMobile from "../../static/images/webv2mobile.png";
import dButtonIos from "../../static/images/dButtonIos.png";
import dButtonPlay from "../../static/images/dButtonPlay.png";
import ReviewCarousel from "../components/ReviewCarousel";
import { Box } from "@mui/material";
import ButtonBase from "@mui/material/ButtonBase";

export default function ProductHero({ scrollHandler }) {
  return (
    <div
      style={{
        backgroundImage: `url(${backgroundImage})`,
        //backgroundSize: "cover",
        backgroundSize: "cover",
        //backgroundRepeat: "no-repeat",
        minHeight: "190vw",
        padding: "5vw",
        marginTop: -80,
      }}
    >
      {/* <ProductHeroLayout> */}
      <Typography
        color="secondary"
        align="left"
        variant="h2"
        sx={{
          mt: { sx: 10, sm: 10, xs: 7, lg: 20 },

          fontSize: { sx: 12, sm: 25, lg: 55, xs: 25 },
        }}
      >
        WashOoo.
      </Typography>
      <Typography
        color="white"
        align="left"
        variant="h1"
        sx={{
          //mb: { sx: 4, sm: 4, xs: 10 },

          fontSize: { sx: 12, lg: 35, sm: 20, xs: 15 },
        }}
      >
        In three simple clicks, your next car wash is booked.
      </Typography>
      <Button
        color="secondary"
        variant="outlined"
        size="small"
        component="a"
        onClick={scrollHandler}
        sx={{
          mt: { sx: 5, md: 5, lg: 5, sm: 5, xs: 3 },
          display: { xs: "none", sm: "inline-block" },
        }}
        // href="/premium-themes/onepirate/sign-up/"
      >
        Get started
      </Button>
      <Box
        sx={{
          //backgroundColor: "blue",
          display: "flex",
          alignItems: "flex-end",
          justifyContent: "flex-end",
          marginTop: "15vw",
          flexDirection: "row",
          display: { xs: "none", lg: "flex" },
        }}
      >
        <Box
          sx={{
            //flex: 1,
            justifySelf: "flex-end",
            alignSelf: "flex-end",
          }}
        >
          {/* <Button
            color="secondary"
            variant="outlined"
            size="small"
            component="a"
            onClick={scrollHandler}
            //sx={{ mt: { sx: 5, md: 5, lg: 5, sm: 5, xs: 3 } }}
            sx={{ display: { xs: "none", sm: "inline-block" } }}
          >
            ios
          </Button> */}
          <ButtonBase
            sx={
              {
                //display: { xs: "none", lg: "block" },
              }
            }
            href={"https://onelink.to/7xccgj"}
          >
            <img
              src={dButtonPlay}
              alt="my image"
              style={{
                height: 60,
                opacity: 0.8,
              }}
              //onClick={this.myfunction}
              onClick={() => "https://onelink.to/7xccgj"}
            />
          </ButtonBase>
          <ButtonBase
            sx={
              {
                //display: { xs: "none", lg: "block" },
                //ml: "20rem",
              }
            }
            href={"https://onelink.to/7xccgj"}
          >
            <img
              src={dButtonIos}
              alt="my image"
              style={{
                height: 60,
                opacity: 0.8,
              }}
              //onClick={this.myfunction}
            />
          </ButtonBase>

          {/* <Button
            color="secondary"
            variant="outlined"
            size="small"
            component="a"
            onClick={scrollHandler}
            sx={{ display: { xs: "none", sm: "inline-block" } }}
            //sx={{ mt: { sx: 5, md: 5, lg: 5, sm: 5, xs: 3 } }}
            // href="/premium-themes/onepirate/sign-up/"
          >
            Android
          </Button> */}
        </Box>
      </Box>
      <div
        style={{
          //backgroundColor: "blue",
          display: "flex",
          alignItems: "flex-end",
          justifyContent: "flex-end",
          marginTop: "15vw",
        }}
      >
        <div>
          {/* /////// */}
          <Typography
            color="secondary"
            align="left"
            variant="h1"
            sx={{
              //mb: { sx: 4, sm: 4, xs: 10 },
              mt: { xs: -5 },
              marginRight: { sm: "29vw", sx: "10vw" },
              fontSize: { sx: 12, lg: 45, sm: 20, xs: 15 },
            }}
          >
            Services
          </Typography>
          <Typography
            color="white"
            align="left"
            variant="h1"
            sx={{
              fontSize: { sx: 12, lg: 20, sm: 17, xs: 10 },
              maxWidth: { md: "30vw", xs: "39vw" },
              textAlign: "justify",
            }}
          >
            A wide selection of services to pamper your car, just pick the
            convenient time without having to wait for lines
          </Typography>
        </div>
      </div>
      <div
        style={{
          //backgroundColor: "blue",
          display: "flex",
          alignItems: "flex-start",
          justifyContent: "flex-start",
          marginTop: "52vw",
        }}
      >
        <div>
          {/* /////// */}
          <Typography
            color="secondary"
            align="left"
            variant="h1"
            sx={{
              //mb: { sx: 4, sm: 4, xs: 10 },
              mt: { xs: -5, lg: 0, sm: 0 },
              marginRight: { sm: "20vw", sx: "10vw" },
              fontSize: { sx: 12, lg: 45, sm: 20, xs: 15 },
            }}
          >
            Monitoring
          </Typography>
          <Typography
            color="white"
            align="left"
            variant="h1"
            sx={{
              fontSize: { sx: 12, lg: 20, sm: 17, xs: 10 },
              maxWidth: { md: "30vw", xs: "39vw" },
              textAlign: "justify",
            }}
          >
            You wont have to wait for your car while its being serviced. With
            active updates on your request and status of your car you could get
            things done while we take care of your car.
          </Typography>
        </div>
      </div>
      {/* <div
        style={{
          //backgroundColor: "blue",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginTop: "20vw",
        }}
        sx={{ display: { xs: "none" } }}
      >
        <Box sx={{ mt: { lg: "20vw" } }}>
          <Button
            color="secondary"
            variant="outlined"
            component="a"
            onClick={scrollHandler}
            //size="large"
            //sx={{ mt: { sx: 5, md: 5, lg: 5, sm: 5, xs: 3 } }}
            sx={{ marginRight: 5, display: { xs: "none", sm: "inline-block" } }}
          >
            Secure
          </Button>
          <Button
            color="secondary"
            variant="outlined"
            size="large"
            component="a"
            onClick={scrollHandler}
            sx={{ marginRight: 5, display: { xs: "none", sm: "inline-block" } }}
            //sx={{ mt: { sx: 5, md: 5, lg: 5, sm: 5, xs: 3 } }}
            // href="/premium-themes/onepirate/sign-up/"
          >
            Fast
          </Button>
          <Button
            color="secondary"
            variant="outlined"
            size="large"
            component="a"
            onClick={scrollHandler}
            sx={{ marginRight: 5, display: { xs: "none", sm: "inline-block" } }}
            //sx={{ mt: { sx: 5, md: 5, lg: 5, sm: 5, xs: 3 } }}
            // href="/premium-themes/onepirate/sign-up/"
          >
            Competitive
          </Button>
        </Box>
      </div> */}

      {/* <picture
      // style={{
      //   backgroundImage: `url(${backgroundImage})`,
      //   backgroundPosition: "center",
      //   backgroundSize: "cover",
      //   backgroundRepeat: "no-repeat",
      //   width: "100vw",
      //   height: "100vh",
      // }}
      //zIndex={-99}
      >
        <source media="(max-width: 450px)" srcSet={backgroundImageMobile} />
        <source media="(min-width: 600px)" srcSet={backgroundImage} />
        <img
          src={backgroundImageMobile}
          style={{
            width: "100%",
            //marginTop: "-10vw",
            //resize: "block",
            zIndex: -99,
          }}
          alt="home"
        />
      </picture> */}
      {/* <backgroundImage></backgroundImage> */}
      {/* </ProductHeroLayout> */}
    </div>
  );
}
