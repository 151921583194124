import * as React from "react";
import Button from "../components/Button";
import Typography from "../components/Typography";
import ProductHeroLayout from "./ProductHeroLayout";
import backgroundImage from "../../static/images/SecondImage.png";
import backgroundImageMobile from "../../static/images/webv2mobile.png";
import ReviewCarousel from "../components/ReviewCarousel";

export default function ProductHeroSecond({ scrollHandler }) {
  return (
    <div
      style={{
        backgroundImage: `url(${backgroundImage})`,
        //backgroundSize: "cover",
        backgroundSize: "cover",
        //backgroundRepeat: "no-repeat",
        minHeight: "190vw",
        padding: "5vw",
        marginTop: 0,
      }}
    >
      {/* <ProductHeroLayout> */}

      <Typography
        color="secondary"
        align="left"
        variant="h2"
        sx={{
          mt: { sx: 0, sm: 0, xs: -10, lg: 0 },

          fontSize: { sx: 12, sm: 25, lg: 85, xs: 30 },
        }}
      >
        WashOo
      </Typography>
      <Typography
        color="white"
        align="left"
        variant="h1"
        sx={{
          //mb: { sx: 4, sm: 4, xs: 10 },

          fontSize: { sx: 12, lg: 45, sm: 20, xs: 18 },
        }}
      >
        No underlying costs everything is clear before you book your service
        with integrated payments.
      </Typography>
      {/* <Button
        color="secondary"
        variant="outlined"
        size="small"
        component="a"
        onClick={scrollHandler}
        sx={{ mt: { sx: 5, md: 5, lg: 5, sm: 5, xs: 3 } }}
        // href="/premium-themes/onepirate/sign-up/"
      >
        Get started
      </Button> */}
    </div>
  );
}
