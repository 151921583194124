import * as React from "react";
import PropTypes from "prop-types";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Paper from "../components/Paper";
import Typography from "../components/Typography";
import BlackLogo from "../../static/images/blackLogo.png";
//import Img

function AppForm(props) {
  const { children } = props;

  return (
    <Box
      sx={{
        display: "flex",
        backgroundRepeat: "no-repeat",
        borderWidth: 1.4,
        //backgroundColor: "red",
      }}
    >
      <Container maxWidth="lg">
        <Box sx={{ mt: 7, mb: 12 }}>
          <div
            style={{
              //backgroundColor: "rgba(134,236,199,1)",
              //backgroundColor: "red",
              display: "flex",
              direction: "row",
              borderRadius: 30,
              boxShadow: "0px 0px 30px  rgba(0,0,0,0.8)",
            }}
          >
            <Paper
              //background="light"
              //background="red"
              sx={{
                py: { xs: 4, md: 8, lg: 15 },
                px: { xs: 3, md: 6 },
                display: { xs: "none", sm: "block" },
              }}
              style={{
                //boxShadow: "0px 0px 30px  rgba(0,0,0,0.8)",
                borderTopLeftRadius: 30,
                borderBottomLeftRadius: 30,
                background: "rgba(134,236,199,1)",
              }}
            >
              {/* <img
                src={require("../../static/images/blackLogo.png")}
                style={{ height: "2vw" }}
                //sx={{ w: { lg: "1vw" } }}
              /> */}
              <Box
                component="img"
                sx={{
                  height: "2vw",
                  height: { xs: 50, sm: 50, lg: 50, md: 50 },
                  //width: 350,
                  //maxHeight: { xs: 233, md: 167 },
                  //maxWidth: { xs: 350, md: 250 },
                  // minHeight: { xs: "5vw" },
                }}
                alt="washoo"
                src={require("../../static/images/blackLogo.png")}
              />
              <Typography
                color="rgba(69 ,69, 69,1)"
                align="left"
                variant="h2"
                sx={{
                  mt: { sx: 0, sm: 0, xs: 0, lg: 0 },
                  mb: { lg: 5, xs: 5, sm: 5, md: 5 },

                  fontSize: { sx: 12, sm: 25, lg: 35, xs: 25 },
                  maxWidth: { lg: 300 },
                }}
              >
                Unlock all the goodies
              </Typography>
              <Typography
                color="rgba(69 ,69, 69,0.5)"
                align="left"
                variant="h1"
                sx={{
                  //mb: { sx: 4, sm: 4, xs: 10 },

                  fontSize: { sx: 12, lg: 15, sm: 10, xs: 15 },
                  maxWidth: { lg: 300 },
                  mr: { lg: 20 },
                }}
              >
                Lorem ipsum dolor sit amet, Lorem ipsum dolor sit amet,
                consectetuer adipiscing elit, sed diam nonum hendrerit in
                vulputate velit esse molestie
              </Typography>
            </Paper>
            <Paper
              //background="light"
              //background="red"
              sx={{
                py: { xs: 4, md: 8, lg: 15 },
                px: { xs: 3, md: 6 },
                ml: { lg: 5 },
                borderTopLeftRadius: { xs: 30 },
                borderBottomLeftRadius: { xs: 30 },
              }}
              style={{
                //boxShadow: "0px 0px 30px  rgba(0,0,0,0.8)",
                borderTopRightRadius: 30,
                borderBottomRightRadius: 30,
                background: "rgba(51,51,51,1)",
                //margin: 20,
                marginRight: -5,
              }}
            >
              {children}
            </Paper>
          </div>
        </Box>
      </Container>
    </Box>
  );
}

AppForm.propTypes = {
  children: PropTypes.node,
};

export default AppForm;
