import * as React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import { Field, Form, FormSpy } from "react-final-form";
import Typography from "./modules/components/Typography";
import AppFooter from "./modules/views/AppFooter";
import AppAppBar from "./modules/views/AppAppBar";
import AppForm from "./modules/views/AppForm";
import { email, required } from "./modules/form/validation";
import RFTextField from "./modules/form/RFTextField";
import FormButton from "./modules/form/FormButton";
import FormFeedback from "./modules/form/FormFeedback";
import withRoot from "./modules/withRoot";
import Button from "./modules/components/Button";
import axios from "axios";
//import { gql, useMutation } from "@apollo/client";

function SignUp() {
  const [sent, setSent] = React.useState(false);
  const [joined, setJoined] = React.useState(false);
  const [name, setName] = React.useState("");
  const [emailV, setEmailV] = React.useState("");
  const [phone, setPhone] = React.useState("");
  const [organization, setOrganization] = React.useState("");
  // const CREATE_ACCOUNT = gql`
  //   mutation createAccountMutation($account: AccountInput!) {
  //     createAccount(account: $account) {
  //       success
  //       account {
  //         id
  //         name
  //         phone
  //         email
  //       }
  //     }
  //   }
  // `;
  // const [CreateAccount] = useMutation(CREATE_ACCOUNT, {
  //   variables: {
  //     account: {
  //       name: name,
  //       email: emailV,
  //       phone: phone,
  //       organization: organization,
  //     },
  //   },
  // });

  const validate = (values) => {
    const errors = required(
      ["firstName", "lastName", "eMail", "phoneNumber"],
      values
    );

    if (!errors.eMail) {
      const emailError = email(values.eMail);
      if (emailError) {
        errors.eMail = emailError;
      }
    }

    return errors;
  };

  const handleSubmit = (values) => {
    const { eMail, firstName, lastName, phoneNumber, org } = values;
    setName(firstName + " " + lastName);
    setEmailV(eMail);
    setPhone(phoneNumber);
    setOrganization(org);
    setSent(true);
    // CreateAccount();
    setJoined(true);
    setSent(false);
  };

  if (joined) {
    return (
      <AppForm>
        <React.Fragment>
          <Typography variant="h3" gutterBottom marked="center" align="center">
            Thank you for joining washoo
          </Typography>
          <Typography variant="body2" align="center">
            We will be in touch.
            {/* <Link href="/premium-themes/onepirate/sign-in/" underline="always">
              Already have an account?
            </Link> */}
          </Typography>
        </React.Fragment>
      </AppForm>
    );
  }
  return (
    <React.Fragment>
      <AppForm id="">
        <React.Fragment>
          <Typography
            variant="h3"
            // gutterBottom
            // marked="center"
            align="center"
            color="secondary"
          >
            Become a partner
          </Typography>
          <Typography variant="body2" align="center">
            .....
            {/* <Link href="/premium-themes/onepirate/sign-in/" underline="always">
              Already have an account?
            </Link> */}
          </Typography>
        </React.Fragment>
        <Form
          onSubmit={handleSubmit}
          subscription={{ submitting: true }}
          validate={validate}
        >
          {({ handleSubmit: handleSubmit2, submitting }) => (
            <Box
              component="form"
              onSubmit={handleSubmit2}
              noValidate
              sx={{ mt: 6 }}
            >
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Field
                    //autoFocus
                    component={RFTextField}
                    disabled={submitting || sent}
                    autoComplete="given-name"
                    fullWidth
                    label="First name"
                    name="firstName"
                    required
                    color="field"
                    sx={{
                      input: { color: "white" },
                      label: { color: "white" },
                      "& .MuiInput-underline:before": {
                        borderBottomColor: "white",
                      },
                      "& .MuiInput-underline:after": {
                        borderBottomColor: "field",
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Field
                    component={RFTextField}
                    //disabled={submitting || sent}
                    autoComplete="family-name"
                    fullWidth
                    label="Last name"
                    name="lastName"
                    required
                    color="field"
                    sx={{
                      input: { color: "white" },
                      label: { color: "white" },
                      "& .MuiInput-underline:before": {
                        borderBottomColor: "white",
                      },
                      "& .MuiInput-underline:after": {
                        borderBottomColor: "field",
                      },
                    }}
                  />
                </Grid>
              </Grid>
              <Field
                autoComplete="email"
                component={RFTextField}
                disabled={submitting || sent}
                fullWidth
                label="Email"
                margin="normal"
                name="eMail"
                required
                color="field"
                sx={{
                  input: { color: "white" },
                  label: { color: "white" },
                  "& .MuiInput-underline:before": {
                    borderBottomColor: "white",
                  },
                  "& .MuiInput-underline:after": {
                    borderBottomColor: "field",
                  },
                }}
              />
              <Field
                fullWidth
                component={RFTextField}
                disabled={submitting || sent}
                required
                name="phoneNumber"
                autoComplete="phone"
                label="phoneNumber"
                type="phoneNumber"
                margin="normal"
                color="field"
                sx={{
                  input: { color: "white" },
                  label: { color: "white" },
                  "& .MuiInput-underline:before": {
                    borderBottomColor: "white",
                  },
                  "& .MuiInput-underline:after": {
                    borderBottomColor: "field",
                  },
                }}
              />

              <FormSpy subscription={{ submitError: true }}>
                {({ submitError }) =>
                  submitError ? (
                    <FormFeedback error sx={{ mt: 2 }}>
                      {submitError}
                    </FormFeedback>
                  ) : null
                }
              </FormSpy>
              <Button
                // sx={{
                //   mt: 3,
                //   mb: 2,
                //   borderRadius: 2,
                //   borderWidth: 2,
                //   borderColor: "red",
                //   input: { color: "white" },
                //   label: { color: "white" },
                //   "& .MuiInput-underline:before": {
                //     borderBottomColor: "white",
                //   },
                //   "& .MuiInput-underline:after": {
                //     borderBottomColor: "field",
                //   },
                // }}
                color="secondary"
                variant="outlined"
                disabled={submitting || sent}
                sx={{ mt: { xs: 5, sm: 10 }, mb: { xs: 3, sm: -3 } }}
                //color="primary"
                fullWidth
                // onSubmit={() => {
                //   axios
                //     .post(`https://api.emailjs.com/api/v1.0/email/send`, {
                //       service_id: "service_aeyxzi3",
                //       template_id: "template_c9aieuf",
                //       user_id: "YgBqopk0uLXdBoDyL",
                //       // template_params: {
                //       //   username: name,
                //       //   phone: phone,
                //       //   email: emailV,
                //       // },
                //     })
                //     .then((res) => {
                //       console.log(res);
                //     })
                //     .catch((e) => console.log(e));
                // }}
                onSubmit={() => console.log("submitted")}
                onClick={() => console.log(phone, emailV, name)}
              >
                {submitting || sent ? "In progress…" : "Join"}
              </Button>
            </Box>
          )}
        </Form>
      </AppForm>
      {/* <AppFooter /> */}
    </React.Fragment>
  );
}

export default withRoot(SignUp);
