import React, { Component } from "react";
import Slider from "react-slick";
import ReviewCard from "./ReviewCard";

export default class ReviewCarousel extends Component {
  render() {
    var settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 4,
      slidesToScroll: 4,
      initialSlide: 0,
      //marginLeft: 20,
      //   appendDots: (dots) => (
      //     <div
      //       style={{
      //         //backgroundColor: "#ddd",
      //         borderRadius: "10px",
      //         //padding: "10px",
      //         //maxLines: 1,
      //         //minWidth: 500,
      //         //flexWrap: "nowrap",
      //       }}
      //     >
      //       <ul
      //         style={{
      //           margin: "0px",
      //           width: 600,
      //           //  maxWidth: 100
      //         }}
      //       >
      //         {" "}
      //         {dots}{" "}
      //       </ul>
      //     </div>
      //   ),
      //   customPaging: (i) => (
      //     <div
      //       style={
      //         {
      //           //width: "30px",
      //           //color: "blue",
      //           //border: "1px blue solid",
      //         }
      //       }
      //     >
      //       {i + 1}
      //     </div>
      //   ),
      //slideCount: 1,

      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
            dots: false,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 0,
            //dots: false,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 0,
            //dots: true,
          },
        },
      ],
    };
    return (
      <div
        style={{
          padding: 100,
          //maxWidth: 420,
        }}
      >
        <h2> Reviews </h2>
        <div>
          <Slider {...settings} style={{ alignItems: "center" }}>
            {Array.from({ length: 15 }).map((item, index) => (
              <ReviewCard key={index}></ReviewCard>
            ))}
          </Slider>
        </div>
      </div>
    );
  }
}
